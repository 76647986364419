import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react"

import SectionVideo from "./section-video"

import Play from "../icons/play"

const VideoModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const styles = {
    content: {
      maxWidth: "80vw",
      my: "auto",
      bg: "transparent",
      boxShadow: "none",
    }
  }

  return (
    <>
      <button className="btn btn--primary-outline btn--banner" onClick={onOpen}>Watch Video <Play /></button>

      <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent sx={styles.content}>
          <ModalBody>
            <SectionVideo />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default VideoModal
