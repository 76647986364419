import React from "react"
import { Flex, Box, Heading, Link, Text } from "@chakra-ui/react"
import PostCard from "../post-card/post-card"
import { StaticImage } from 'gatsby-plugin-image'
import SlideUp from "../slide-up/slide-up"
import TopRightArrow from "../icons/top-right-arrow"

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    my: { base: "100px", lg: "200px" },
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: "0px 24px",
  },
  "header-box": {
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: { base: "center", lg: "space-between" },
    alignItems: { base: "flex-start", lg: "flex-end" },
    gap: "24px",
    mb:{ base: "50px", md: "100px" },
  },
  heading: {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: {base: "24px", lg:"28px", xl:"34px"},
    lineHeight: "1.4",
    color: "#1B263F",
  },
  "heading-text": {
    fontSize: {base: "16px", lg:"18px"},
    lineHeight: "1.4",
    maxWidth: "610px",
    mt: "24px",
  },
  "inner-box": {
    gap: "24px",
    display: "grid",
    gridTemplateColumns: { base: "1fr", md: "1fr 1fr 1fr" },
  },
}

const SectionResults = () => (
  <Box sx={styles.root}>
    <Box sx={styles.container}>
      <Flex sx={styles["header-box"]}>
        <Box>
          <Heading sx={styles.heading}>
            Reshaping Healthcare Economics
          </Heading>
          <Text sx={styles["heading-text"]}>
            Delivering actionable insights to reduce costs, optimize revenue, and improve outcomes.
          </Text>
        </Box>
        <Link className="btn btn--primary" href="https://avantgardehealth.com/schedule-a-demo/">
            Schedule a Demo
            <TopRightArrow />
        </Link>
      </Flex>
      <Flex sx={styles["inner-box"]}>
        <SlideUp className="flex">
          <PostCard
            title={"Improve Surgical Cost Management"}
            text={"Implement targeted strategies to reduce expenses and enhance operational efficiency in surgical procedures."}
            url={"https://avantgardehealth.com/caremeasurement/"}
          >
            <StaticImage
              src="../../images/reshaping-healthcare/improve-surgical-cost.png"
              alt="Physician"
              placeholder="blurred"
            />
          </PostCard>
        </SlideUp>
        <SlideUp className="flex">
          <PostCard
            title={"Maximize Facility Revenue"}
            text={"Leverage innovative solutions to increase profitability and ensure financial sustainability for your facility."}
            url={"https://avantgardehealth.com/surgical-coding-optimization/"}
          >
            <StaticImage
              src="../../images/reshaping-healthcare/maximize-facility-revenue.png"
              alt="Physician"
              placeholder="blurred"
            />
          </PostCard>
        </SlideUp>
        <SlideUp className="flex">
          <PostCard
            title={"Improve Patient Outcomes"}
            text={"Focus on quality care and patient satisfaction, driving better health results and fostering trust within the community."}
            url={"https://avantgardehealth.com/eras/"}
          >
            <StaticImage
              src="../../images/reshaping-healthcare/patient-outcomes.png"
              alt="Physician"
              placeholder="blurred"
            />
          </PostCard>
        </SlideUp>
      </Flex>
    </Box>
  </Box>
)

export default SectionResults
