import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import SlideUp from "../slide-up/slide-up"
import Slider from "react-slick"
import LeftArrow from "../icons/left-arrow"
import RightArrow from "../icons/right-arrow"

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    my: { base: "100px", lg: "200px" },
    overflow: "hidden",
    pb: { base: "112px", md: "0px" },
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: "0px 24px",
  },
  "heading-one": {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: { base: "24px", lg: "28px", xl: "34px" },
    lineHeight: "1.4",
    color: "#111",
    textAlign: "center",
    mb: { base: "70px", lg: "50px", xl: "100px" },
    maxWidth: "587px",
    mx: "auto",
  },
  blocks: {
    margin: "0 auto",
    height: "100%",
    display: { base: "none", md: "grid" },
    gap: "24px",
    gridTemplateColumns: {
      base: "1fr",
      md: "1fr 1fr",
      "2xl": "1fr 1fr 1fr 1fr",
    },
    justifyContent: "center",
    maxWidth: { base: "724px", "2xl": "100%" },
  },
  "block-title": {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: { base: "40px", md: "40px", lg: "40px" },
    lineHeight: "1.4",
    color: "#A3E347",
    mt: "2px",
  },
  "block-text": {
    mt: { base: "32px", xl: "36px" },
    fontSize: { base: "16px", xl: "18px" },
    lineHeight: "1.3",
    color: "#FFFFFF",
  },
  "quote-box": {
    margin: "0 auto",
    mt: "40px",
    flexDirection: "column",
    alignItems: "center",
    width: { base: "100%", md: "100%", lg: "800px" },
  },
  "quote-title": {
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    color: "#041424",
    textAlign: "center",
  },
  "quote-text": {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  box: {
    position: "relative",
    width: { base: "88%", md: "350px", xl: "100%" },
    height: "203px",
    mx: "auto",
  },
  "box-bg": {
    position: "absolute",
    inset: "0",
  },
  "box-content": {
    position: "relative",
    zIndex: "2",
    p: { base: "36px 47px 36px 26px", lg: "36px 58px 36px 32px" },
  },
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#e3f97b",
        color: "#033F47",
      }}
      onClick={onClick}
    >
      <RightArrow />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#e3f97b",
        color: "#033F47",
      }}
      onClick={onClick}
    >
      <LeftArrow />
    </div>
  )
}

const settings = {
  dots: false,
  infinite: true,
  speed: 700,
  slidesToScroll: 1,
  slidesToShow: 1.065,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
}

const Block = ({ title, Image, text, to, mx, mt }) => (
  <Box sx={styles.box}>
    <Box sx={styles["box-bg"]}>
      <StaticImage
        src="../../images/folder-bg.svg"
        alt="Home page hero image"
        placeholder="blurred"
      />
    </Box>
    <Box sx={styles["box-content"]}>
      <Heading sx={styles["block-title"]}>{title}</Heading>
      <Text sx={styles["block-text"]}>{text}</Text>
    </Box>
  </Box>
)

const SectionCaremeasurement = () => (
  <Box sx={styles.root} className="transforming-healthcare">
    <Box sx={styles.container}>
      <Heading sx={styles["heading-one"]}>
        Transforming Healthcare Outcomes and Cost Management
      </Heading>
      <Flex sx={styles.blocks} className="transforming-healthcare-blocks">
        <SlideUp>
          <Block title="$2,300" text="Decrease in 90-day readmission rates" />
        </SlideUp>
        <SlideUp>
          <Block
            title="33%"
            text="Reduction in discharges to SNFs, Inpatient Rehab, etc."
            mx="24px"
            mt={{ base: "48px", md: "48px", lg: "0px" }}
          />
        </SlideUp>
        <SlideUp>
          <Block
            title="15%"
            text="Decrease in 90-day readmission rates"
            mt={{ base: "48px", md: "48px", lg: "0px" }}
          />
        </SlideUp>
        <SlideUp>
          <Block
            title="13%"
            text="Reduction in average length of stay"
            mt={{ base: "48px", md: "48px", lg: "0px" }}
          />
        </SlideUp>
      </Flex>
      <Slider {...settings} className="folder-slides">
        <Block title="$2,300" text="Decrease in 90-day readmission rates" />
        <Block
          title="33%"
          text="Reduction in discharges to SNFs, Inpatient Rehab, etc."
        />
        <Block title="15%" text="Decrease in 90-day readmission rates" />
        <Block title="13%" text="Reduction in average length of stay" />
      </Slider>
    </Box>
  </Box>
)

export default SectionCaremeasurement
