import React from 'react'

const Shadow = () => {
  return (
    <svg width="659" height="31" viewBox="0 0 659 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="329.347" cy="15.2572" rx="328.843" ry="15.1986" fill="url(#paint0_radial_4064_2444)" fill-opacity="0.7"/>
      <defs>
      <radialGradient id="paint0_radial_4064_2444" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(329.347 15.2572) scale(328.843 15.1986)">
      <stop stop-color="#03353B"/>
      <stop offset="1" stop-color="#033F47"/>
      </radialGradient>
      </defs>
    </svg>
  )
}

export default Shadow