import React from "react"

const Play = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21875 11.2812C4.28125 12.3229 5.54167 12.8438 7 12.8438C8.45833 12.8438 9.70833 12.3229 10.75 11.2812C11.8125 10.2188 12.3438 8.95833 12.3438 7.5C12.3438 6.04167 11.8125 4.79167 10.75 3.75C9.70833 2.6875 8.45833 2.15625 7 2.15625C5.54167 2.15625 4.28125 2.6875 3.21875 3.75C2.17708 4.79167 1.65625 6.04167 1.65625 7.5C1.65625 8.95833 2.17708 10.2188 3.21875 11.2812ZM2.28125 2.8125C3.59375 1.5 5.16667 0.84375 7 0.84375C8.83333 0.84375 10.3958 1.5 11.6875 2.8125C13 4.10417 13.6562 5.66667 13.6562 7.5C13.6562 9.33333 13 10.9062 11.6875 12.2188C10.3958 13.5104 8.83333 14.1562 7 14.1562C5.16667 14.1562 3.59375 13.5104 2.28125 12.2188C0.989583 10.9062 0.34375 9.33333 0.34375 7.5C0.34375 5.66667 0.989583 4.10417 2.28125 2.8125ZM5.65625 10.5V4.5L9.65625 7.5L5.65625 10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Play
