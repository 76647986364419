import React from 'react'
import { Box, Flex, Heading, Text, Link } from '@chakra-ui/react'
import RightArrow from '../icons/right-arrow'

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    lineHeight: "1.4",
    flex: "1",
    borderRadius: "12px",
    bg: "#F8F7F2",
    p: "20px",
    position: "relative",
    color: "#111",
    display: "flex",
    flexDirection: "column",
    _hover: {
      bg: "#FAFFE1",
    }
  },
  title: {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: { base: "22px", lg: "24px" },
    lineHeight: "1.4",
    color: "#111",
    mt: "24px",
  },
  text: {
    fontSize: {base: "16px", lg:"18px"},
    color: "#333",
    my: "24px",
  },
  "card-content": {
    flexDirection: "column",
    gap: "12px",
    justifyContent: "flex-start",
    mt: "24px",
  },
  link: {
    position: "absolute",
    inset: "0",
  },
  "text-link": {
    mt: "auto",
  }
}

const PostCard = ({text, title, children, url}) => {
  return (
    <Box sx={styles.root}>
      {children}
      <Heading sx={styles.title}>{title}</Heading>
      <Text sx={styles.text}>{text}</Text>
      <Text sx={styles["text-link"]}><Link href={url} className='btn btn--text btn--card'>Learn More <RightArrow /></Link></Text>
      <Link href={url} sx={styles.link} />
    </Box>
  )
}

export default PostCard