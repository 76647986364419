import React from "react"

const LeftArrow = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6662 19.5566L3.99951 12.5566M3.99951 12.5566L10.6662 5.55664M3.99951 12.5566L19.9995 12.5566"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default LeftArrow
