import React from "react"
import Lottie from "lottie-react"

const LottieAnimate = props => {
  const { src } = props

  return (
    <Lottie animationData={src} loop={true} />
  )
}

export default LottieAnimate