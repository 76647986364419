import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LottieAnimate from "../lottie-animation/lottie-animation"
import { Flex, Heading, Box, Text, UnorderedList, ListItem } from "@chakra-ui/react"
import Tab from "../tab/tab"

import ImprovePatientThroughputAmination from "../../animations/Diagram1.json"
import MaximizeRevenueAnimation from "../../animations/Diagram2.json"
import BundledPaymentAnimation from "../../animations/Diagram3.json"
import FinancialMargin from "../../animations/diagram4.json"
import OptimizeSitecare from "../../animations/diagram5.json"
import RightArrow from "../icons/right-arrow"

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    bg: "rgb(3,63,71)",
    bg: "#1d273f",
    my: { base: "100px", lg: "200px" },
    py: { base: "80px", lg: "130px" },
    overflow: "hidden",
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: "0px 24px",
  },
  heading: {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    color: "#FFFFFF",
    textAlign: "center",
    mb: "24px",
    fontSize: {base: "24px", lg:"28px", xl:"34px"},
    lineHeight: "1.4",
  },
  "sub-heading": {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: {base: "16px", lg:"18px"},
  },
  "content-heading": {
    fontFamily: `"Lora", serif`,
    fontWeight: "700",
    color: "#FFFFFF",
    fontSize: { base: "20px", lg: "30px"},
    mb: "16px",
  },
  "content-text": {
    color: "#F8F8F8",
    fontSize: { base: "18px", lg: "24px"},
  },
  list: {
    color: "#F8F8F8",
    fontSize: { base:"18px", lg: "20px"},              
  },
  "tab-content": {
    gap: { base: "50px", md:"100px"},
    display: "grid",
    gridTemplateColumns: { base: "1fr", xl: "3fr 2fr" },
  },
  "tab-image": {
    flexGrow: "1",
    flex: "1",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    position: "relative",
  },
  "tab-texts": {
    flexGrow: "1",
    flex: "1",
    pl: {lg: "20px"},
    position: "relative",
  },
  gradient: {
    position: "absolute",
    bottom: { base: "-233px", md: "-232px", lg:"-217px", xl: "unset"},
    top: { xl: "-219px"},
    right: { base: "-127px", md: "-56px", xl: "-258px"}
  }
}

const navigateToContactUs = () => navigate("/contact-us/")

const tabs = [
  {
    label: "Improve Financial Margins",
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.6667 26.667H5.33337V5.33366"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.33337 22L16 12L20 16L26 10"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    content: (
      <Flex sx={styles["tab-content"]}>
        <Box sx={styles["tab-image"]}>
          <Box sx={styles["tab-image-box"]} className="tab-image-box">
            <LottieAnimate src={FinancialMargin} />
          </Box>
          <Box sx={styles.gradient}>
            <StaticImage
              src="../../images/green-gradient.png"
              alt="Gradient"
              placeholder="blurred"
            />
          </Box>
        </Box>
        <Box sx={styles["tab-texts"]}>
          <Heading sx={styles["content-heading"]}>
            Reduce unnecessary expenses and drive cost efficiency across
            procedures
          </Heading>
          <Text sx={styles["content-text"]}>
            Benchmark supply costs, renegotiate vendor contracts, and reduce
            waste while safeguarding care quality.
          </Text>
          <hr className="line" />
          <UnorderedList sx={styles.list}>
            <ListItem className="tabs__item-list">
              <span>Reduce supply costs by up to</span>{" "}
              <span className="text-green text-regular">20%</span>.
              <p>
                <a className="btn btn--text text-white text-underline text-italic tabs__item-text mt-4" href="https://avantgardehealth.com/mgb-newton-wellesley-hospital-case-study">
                  Discover how one hospital achieved $5.8 million through
                  targeted cost optimization.
                </a>
              </p>
            </ListItem>
          </UnorderedList>
          <a href="https://avantgardehealth.com/schedule-a-demo/" className="btn btn--text text-white mt-7">
            Schedule a Demo{" "}
            <span className="text-green">
              <RightArrow />
            </span>
          </a>
        </Box>
      </Flex>
    ),
  },
  {
    label: "Improve Patient Throughput",
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0001 29.3337C23.3639 29.3337 29.3334 23.3641 29.3334 16.0003C29.3334 8.63653 23.3639 2.66699 16.0001 2.66699C8.63628 2.66699 2.66675 8.63653 2.66675 16.0003C2.66675 23.3641 8.63628 29.3337 16.0001 29.3337Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6667 15.9997C14.2223 15.9997 17.7779 15.9997 21.3334 15.9997M10.6667 15.9997V9.33301M10.6667 15.9997V22.6663M21.3334 15.9997V22.6663M21.3334 15.9997V9.33301"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    content: (
      <Flex sx={styles["tab-content"]}>
        <Box sx={styles["tab-image"]}>
          <Box sx={styles["tab-image-box"]} className="tab-image-box">
            <LottieAnimate src={ImprovePatientThroughputAmination} />
          </Box>
          <Box sx={styles.gradient}>
            <StaticImage
              src="../../images/green-gradient.png"
              alt="Gradient"
              placeholder="blurred"
            />
          </Box>
        </Box>
        <Box sx={styles["tab-texts"]}>
          <Heading sx={styles["content-heading"]}>
            Improve overall efficiency across surgical care to increase
            patient throughput.
          </Heading>
          <Text sx={styles["content-text"]}>
            Address bottlenecks in preoperative, intraoperative, and
            postoperative workflows to optimize patient flow.
          </Text>
          <hr className="line" />
          <UnorderedList sx={styles.list}>
            <ListItem className="tabs__item-list">
              <span>Increase patient</span>{" "}
              <span className="text-green text-regular">throughput by 10-15%</span> <span>without
              adding resources.</span>
              <Text >
                <a className="btn btn--text text-white text-underline text-italic tabs__item-text mt-4" href="https://avantgardehealth.com/hershey-medical-center-case-study">
                  Learn how one hospital improved workflow efficiency to treat
                  more patients annually.
                </a>
              </Text>
            </ListItem>
          </UnorderedList>
          <a href="https://avantgardehealth.com/schedule-a-demo/" className="btn btn--text text-white mt-7">
            Schedule a Demo{" "}
            <span className="text-green">
              <RightArrow />
            </span>
          </a>
        </Box>
      </Flex>
    ),
  },
  {
    label: "Optimize Site of Care",
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3333 5.33301H7.33325C6.22868 5.33301 5.33325 6.22844 5.33325 7.33301V27.333C5.33325 28.4376 6.22868 29.333 7.33325 29.333H15.9999"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M20.6667 5.33301H24.6667C25.7713 5.33301 26.6667 6.22844 26.6667 7.33301V19.9997"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M10.6667 8.73301V5.99967C10.6667 5.63148 10.9652 5.33301 11.3334 5.33301C11.7016 5.33301 12.0057 5.0344 12.0688 4.67165C12.2663 3.53529 13.0321 1.33301 16.0001 1.33301C18.9681 1.33301 19.7338 3.53529 19.9314 4.67165C19.9945 5.0344 20.2986 5.33301 20.6667 5.33301C21.0349 5.33301 21.3334 5.63148 21.3334 5.99967V8.73301C21.3334 9.06438 21.0648 9.33301 20.7334 9.33301H11.2667C10.9354 9.33301 10.6667 9.06438 10.6667 8.73301Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M20.6667 27.333L23.3334 29.9997L30.0001 23.333"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    content: (
      <Flex sx={styles["tab-content"]}>
        <Box sx={styles["tab-image"]}>
          <Box sx={styles["tab-image-box"]} className="tab-image-box">
            <LottieAnimate src={OptimizeSitecare} />
          </Box>
          <Box sx={styles.gradient}>
            <StaticImage
              src="../../images/green-gradient.png"
              alt="Gradient"
              placeholder="blurred"
            />
          </Box>
        </Box>
        <Box sx={styles["tab-texts"]}>
          <Heading sx={styles["content-heading"]}>
            Improve margins and efficiency by placing cases in the right
            setting
          </Heading>
          <Text sx={styles["content-text"]}>
            Analyze costs to allocate procedures strategically between
            inpatient, outpatient, and ASC settings.
          </Text>
          <hr className="line" />
          <a href="https://avantgardehealth.com/schedule-a-demo/" className="btn btn--text text-white">
            Schedule a Demo{" "}
            <span className="text-green">
              <RightArrow />
            </span>
          </a>
        </Box>
      </Flex>
    ),
  },
  {
    label: "Maximize Revenue Through Coding Accuracy",
    shortLabel: "Maximize Revenue",
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 27.4V4.6C4 4.26863 4.26863 4 4.6 4H27.4C27.7314 4 28 4.26863 28 4.6V27.4C28 27.7314 27.7314 28 27.4 28H4.6C4.26863 28 4 27.7314 4 27.4Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          d="M20 11.333C19.0867 10.4197 17.4782 9.78441 16 9.74463M12 19.9997C12.8593 21.1454 14.457 21.7989 16 21.8543M16 9.74463C14.2412 9.6973 12.6667 10.493 12.6667 12.6664C12.6667 16.6663 20 14.6664 20 18.6663C20 20.9477 18.0482 21.9279 16 21.8543M16 9.74463V7.33301M16 21.8543V24.6663"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    content: (
      <Flex sx={styles["tab-content"]}>
        <Box sx={styles["tab-image"]}>
          <Box sx={styles["tab-image-box"]} className="tab-image-box">
            <LottieAnimate src={MaximizeRevenueAnimation} />
          </Box>
          <Box sx={styles.gradient}>
            <StaticImage
              src="../../images/green-gradient.png"
              alt="Gradient"
              placeholder="blurred"
            />
          </Box>
        </Box>
        <Box sx={styles["tab-texts"]}>
          <Heading sx={styles["content-heading"]}>
            Eliminate revenue leakage and ensure accurate reimbursement.
          </Heading>
          <Text sx={styles["content-text"]}>
            Ensure coding accuracy to capture full reimbursements, maintain
            compliance, and recover lost revenue opportunities.
          </Text>
          <hr className="line" />
          <UnorderedList sx={styles.list}>
            <ListItem className="tabs__item-list">
              <span><span className="text-green text-regular">Enhance revenue</span> by recovering missed reimbursements through improved coding accuracy.</span>
              <p>
                <a className="btn btn--text text-white text-underline text-italic tabs__item-text mt-4" href="https://avantgardehealth.com/surgical-coding-optimization/">
                  See how one system optimized procedural coding to improve
                  revenue performance.
                </a>
              </p>
            </ListItem>
          </UnorderedList>
          <a href="https://avantgardehealth.com/schedule-a-demo/" className="btn btn--text text-white mt-7">
            Schedule a Demo{" "}
            <span className="text-green">
              <RightArrow />
            </span>
          </a>
        </Box>
      </Flex>
    ),
  },
  {
    label: "Succeed in Bundled Payments",
    icon: (
      <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33325 24.667V12.667C5.33325 11.5624 6.22868 10.667 7.33325 10.667H14.9333H19.7333H27.3333C28.4378 10.667 29.3333 11.5624 29.3333 12.667V24.667C29.3333 25.7716 28.4378 26.667 27.3333 26.667H19.7333H14.9333H7.33325C6.22868 26.667 5.33325 25.7716 5.33325 24.667Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.6667 18.6803L24.6801 18.6655"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 18.6803L10.0133 18.6655"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.33325 21.333H3.33325C2.22868 21.333 1.33325 20.4376 1.33325 19.333V7.33301C1.33325 6.22844 2.22868 5.33301 3.33325 5.33301H23.3333C24.4378 5.33301 25.3333 6.22844 25.3333 7.33301V10.6663"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.3333 21.3333C15.8606 21.3333 14.6667 20.1394 14.6667 18.6667C14.6667 17.1939 15.8606 16 17.3333 16C18.8061 16 20 17.1939 20 18.6667C20 20.1394 18.8061 21.3333 17.3333 21.3333Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    content: (
      <Flex sx={styles["tab-content"]}>
        <Box sx={styles["tab-image"]}>
          <Box sx={styles["tab-image-box"]} className="tab-image-box">
            <LottieAnimate src={BundledPaymentAnimation} />
          </Box>
          <Box sx={styles.gradient}>
            <StaticImage
              src="../../images/green-gradient.png"
              alt="Gradient"
              placeholder="blurred"
            />
          </Box>
        </Box>
        <Box sx={styles["tab-texts"]}>
          <Heading sx={styles["content-heading"]}>
            Maximize profitability in bundled payment programs like TEAM or
            BPCI.
          </Heading>
          <Text sx={styles["content-text"]}>
            Optimize care delivery, control costs, and align teams to meet
            performance benchmarks.
          </Text>
          <hr className="line" />
          <UnorderedList sx={styles.list}>
            <ListItem className="tabs__item-list">
              <span>
                One health system{" "}
                <span className="text-green text-regular">
                  reduced costs by 15% below CMS targets
                </span>{" "}
                while decreasing 90-day mortality rates from 34% to 25% and
                90-day readmission rates from 30% to 25%, even with increased
                patient acuity.
              </span>
            </ListItem>
          </UnorderedList>
          <a href="https://avantgardehealth.com/schedule-a-demo/" className="btn btn--text text-white mt-7">
            Schedule a Demo{" "}
            <span className="text-green">
              <RightArrow />
            </span>
          </a>
        </Box>
      </Flex>
    ),
  },
]
const SectionBlueDoctor = () => (
  <Box sx={styles.root}>
    <Box sx={styles.container}>
      <Box>
        <Heading sx={styles.heading}>
          Explore CareMeasurement
        </Heading>
        <Text sx={styles["sub-heading"]}>The Next Generation of Optimizing Costs, Revenue, and Patient Care</Text>
      </Box>
      <Box>
        <Tab tabs={tabs} />
      </Box>
    </Box>
  </Box>
)

export default SectionBlueDoctor
