import React from "react"
import Video from "../video"
import { Box } from "@chakra-ui/react"

const styles = {
  root: {
    margin: "0 auto",
    maxWidth: "1200px",
    width: "100%",
    borderRadius: "12px",
    overflow: "hidden"
  },
}

const SectionVideo = () => (
  <Box sx={styles.root}>
    <Video video="https://player.vimeo.com/video/764943714?h=3ff7b3d987" />
  </Box>
)

export default SectionVideo
