import React, { useState } from "react"
import { Flex, Text } from "@chakra-ui/react"

const Tab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label)

  const styles = {
    "tab-navigation": {
      gap: { base: "16px", md:"24px"},
      maxWidth: "1196px",
      mx: "auto",
      flexWrap: "wrap",
      justifyContent: "center",
      mb: { base: "50px", md: "144px", lg: "154px" },
      mt: { base: "50px", md: "80px", lg: "100px" },
    },
    "long-label":{
      display: {base: "none", md: "inline",},
    },
    "short-label":{
      display: {base: "inline", md: "none",},
    }
  }

  return (
    <div className="tabs">
      <Flex sx={styles["tab-navigation"]}>
        {tabs &&
          tabs.map(tab => (
            <>
              <button className={activeTab === tab.label ? "btn btn--tab btn--white-outline-active" : "btn btn--tab btn--white-outline"} onClick={() => setActiveTab(tab.label)}>
                <span className="tab-navigation-icon">{tab.icon}</span>
                <Text sx={styles["long-label"]}>{tab.label}</Text>
                <Text sx={styles["short-label"]}>{tab.shortLabel ? tab.shortLabel : tab.label}</Text>
              </button>
            </>
          ))}
      </Flex>
      <div className="tab-content">
        {tabs &&
          tabs.map(
            tab =>
              activeTab === tab.label && (
                <div key={tab.label} className="tab-panel">
                  {tab.content}
                </div>
              )
          )}
      </div>
    </div>
  )
}

export default Tab
