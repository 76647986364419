import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Heading,
  Box,
  Link,
} from "@chakra-ui/react"
import SlideUp from "../slide-up/slide-up"
import TopRightArrow from "../icons/top-right-arrow"
import RightArrow from "../icons/right-arrow"

import GundersenImage from "../../images/Gundersen-Health-System.png";
import PennstateImage from "../../images/hershey-medical-center.png";

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    my: { base: "100px", lg: "200px" },
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: "0px 24px",
  },
  "inner-box": {
    display: "grid",
    gridTemplateColumns: { base: "1fr", md: "1fr 1fr"},
    margin: "0 auto",
    mt: "36px",
    width: "100%",
    gap: "24px"
  },
  "left-box": {
    flexDirection: { base: "column", lg: "row" },
  },

  "right-box": {
    width: { base: "100%", md: "100%", lg: "432px" },
    flexDirection: "column",
    alignItems: "center",
  },

  "header-box": {
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: { base: "center", lg: "space-between" },
    alignItems: { base: "flex-start", lg: "flex-end" },
    gap: "24px",
    mb:{ base: "50px", md: "100px" },
  },

  "heading-one": {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    margin: "0 auto",
    fontSize: {base: "24px", lg:"28px", xl:"34px"},
    lineHeight: "1.4",
    color: "#111",
  },
  "heading-text": {
    fontSize: {base: "16px", lg:"18px"},
    lineHeight: "1.4",
    maxWidth: "610px",
    mt: "24px",
    color: "#333"
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  image: {
    textAlign: "center",
    mt: { base: "40px", md: "40px", lg: "0px" },
    width: { base: "247px", md: "247px", lg: "281px" },
    height: { base: "320px", md: "320px", lg: "364px" },
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "228px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  rootCard: {
    borderRadius: "12px",
    bg: "#F8F7F2",
    p: "20px",
    position: "relative",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    _hover: {
      bg: "#FAFFE1",
    }
  },
  logo: {
    position: "absolute",
    bottom: "-8px",
    left: "0",
  },
  "card-image": {
    position: "relative",
  },
  "card-brief": {
    my: "24px",
    fontSize: {base: "16px", lg:"18px"},
  },
  "link-wrapper": {
    mt: "auto"
  },
  links: {
    position: "absolute",
    inset: 0,
  }
}

const SectionCaseStudy = () => (
  <Box sx={styles.root}>
    <Box sx={styles.container}>
      <Flex sx={styles["header-box"]}>
        <Box>
          <Heading sx={styles["heading-one"]}>
            Real World Results
          </Heading>
          <Text sx={styles["heading-text"]}>
            See how top hospitals and ASCs are using Avant-garde Health to drive real, meaningful outcomes.
          </Text>
        </Box>
        <Link className="btn btn--primary" href="https://avantgardehealth.com/schedule-a-demo/">
          Schedule a Demo
          <TopRightArrow />
        </Link>
      </Flex>
      <Flex sx={styles["inner-box"]}>
        <SlideUp className="flex">
            <Box sx={styles.rootCard}>
              <Box sx={styles["card-image"]}>
                <svg
                  width="100%"
                  viewBox="0 0 200 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid meet"
                  className="mask-svg"
                >
                  <mask id="custom-clip">
                    <path
                      d="M12 0H688C694.627 0 700 5.37259 700 12V318C700 324.627 694.627 330 688 330H268.745C263.783 330 259.332 326.945 257.548 322.314L243.243 285.183C241.459 280.552 237.008 277.497 232.045 277.497H12C5.37256 277.497 0 272.125 0 265.497V138.749V12C0 5.37258 5.37256 0 12 0Z"
                      fill="white"
                    />
                  </mask>
                </svg>

                <div class="clipped-element">
                  <img src={PennstateImage} alt="Hershey medical center image" />
                </div>
                <Box sx={styles.logo}>
                  <StaticImage
                    src="../../images/client-logo/pennState.svg"
                    alt="Home page hero image"
                    placeholder="blurred"
                  />
                </Box>
              </Box>
              <Text sx={styles["card-brief"]}>Discover how PennState Health achieved significant cost reductions and improved patient outcomes through targeted vendor negotiations and streamlined patient care processes with Avant-garde Health.</Text>
              <Text sx={styles["link-wrapper"]}><Link href="https://avantgardehealth.com/hershey-medical-center-case-study/" className='btn btn--text btn--card'>Learn More <RightArrow /></Link></Text>
              <Link href="https://avantgardehealth.com/hershey-medical-center-case-study/" sx={styles.links} />
            </Box>
        </SlideUp>
        <SlideUp className="flex">
          <Box sx={styles.rootCard}>
            <Box sx={styles["card-image"]}>
              <svg
                width="100%"
                viewBox="0 0 200 150"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid meet"
                className="mask-svg"
              >
                <mask id="custom-clip">
                  <path
                    d="M12 0H688C694.627 0 700 5.37259 700 12V318C700 324.627 694.627 330 688 330H268.745C263.783 330 259.332 326.945 257.548 322.314L243.243 285.183C241.459 280.552 237.008 277.497 232.045 277.497H12C5.37256 277.497 0 272.125 0 265.497V138.749V12C0 5.37258 5.37256 0 12 0Z"
                    fill="white"
                  />
                </mask>
              </svg>

              <div class="clipped-element">
                <img src={GundersenImage} alt="Gundersen health system image" />
              </div>
              <Box sx={styles.logo}>
                <StaticImage
                  src="../../images/client-logo/massGeneral.svg"
                  alt="Home page hero image"
                  placeholder="blurred"
                />
              </Box>
            </Box>
            <Text sx={styles["card-brief"]}>Discover how Newton Wellesley Hospital improved performance across supply spending, operating room  efficiency, length of stay, and coding accuracy with Avant-garde Health.</Text>
            <Text sx={styles["link-wrapper"]}><Link href="https://avantgardehealth.com/mgb-newton-wellesley-hospital-case-study" className='btn btn--text btn--card'>Learn More <RightArrow /></Link></Text>
            <Link href="https://avantgardehealth.com/mgb-newton-wellesley-hospital-case-study" sx={styles.links} />
          </Box>
        </SlideUp>
      </Flex>
    </Box>
  </Box>
)

export default SectionCaseStudy
