import React from "react";
import { Box, keyframes, position } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Marquee from "react-fast-marquee";

const marquee = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`;

const Clients = () => {
  const styles = {
    root: {
      bg: "#FFFFFF",
      height: "122px",
      position: "relative",
      maxWidth: { lg: "80vw" },
      ml: { lg:"auto" },
      px: 0,
      pt: { lg: "11px"}
    },
    box: {
      mx: "32px"
    }
  }
  return (
    <Box className="client-logo-wrapper" sx={styles.root}>
      <Marquee speed={120} gradient={true} gradientWidth={100} gradientColor={[255, 255, 255]}>
        <Box sx={styles.box}>
          <StaticImage
            src="../../images/client-logo/mass-general-hospital.png"
            alt="Mass General Hospital"
            placeholder="blurred"
          />
        </Box>
        <Box sx={styles.box}>
          <StaticImage
            src="../../images/client-logo/new-england-baptist-logo.png"
            alt="New England Baptist"
            placeholder="blurred"
          />
        </Box>
        <Box sx={styles.box}>
          <StaticImage
            src="../../images/client-logo/penn-state-hershey-medical-center.png"
            alt="Penn State Hershey"
            placeholder="blurred"
          />
        </Box>
        <Box sx={styles.box}>
          <StaticImage
            src="../../images/client-logo/ortho-tennessee.png"
            alt="Ortho Tennessee"
            placeholder="blurred"
          />
        </Box>
        <Box sx={styles.box}>
          <StaticImage
            src="../../images/client-logo/rothman-orthopaedics.png"
            alt="Rothman Orthopaedics"
            placeholder="blurred"
          />
        </Box>
      </Marquee>
    </Box>
  );
};

export default Clients;


