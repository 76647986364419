import React from "react"

import Layout from "../components/layout"
import SectionHero from "../components/home/section-hero"
import SectionCaremeasurement from "../components/home/section-caremeasurement"
import SectionBlueDoctor from "../components/home/section-blue-doctor"
import SectionQuote from "../components/home/section-quote"
import SectionResults from "../components/home/section-results"
import SectionHowItWorks from "../components/home/section-how-it-works"
import SectionCaseStudy from "../components/home/section-case-study"

class RootIndex extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={"Healthcare Data Analytics | Avant-Garde Health"}
      >
        <SectionHero />
        <SectionCaremeasurement />
        <SectionBlueDoctor />
        {/* <SectionVideo /> */}
        <SectionQuote />
        <SectionResults />
        <SectionCaseStudy />
        <SectionHowItWorks />
      </Layout>
    )
  }
}

export default RootIndex
