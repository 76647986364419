import React from "react"

const TopRightArrow = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 0.679688H12.8203V9H11.1797V3.49219L1.49219 13.1797L0.320312 12.0078L10.0078 2.32031H4.5V0.679688Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TopRightArrow
