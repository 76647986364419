import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Text, Heading, Box } from "@chakra-ui/react"

import VideoModal from "./video-modal"
import Clients from "./clients"

import SlideUp from "../slide-up/slide-up"
import RightArrow from "../icons/right-arrow"
import Shadow from "../icons/shadow"

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    mx: { base: "10px", lg: "14px" },
    bg: "#033F47",
    color: "#FFFFFF",
    borderRadius: { base: "0 0 12px 12px", lg: "0 0 12px 0" },
    mb: { base: "50px", lg: "0" },
    height: { xl: "calc(100dvh - 241px)" },
    display: "flex",
    alignItems: "center",
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: { base: "0px 16px", lg: "0px 24px" },
    width: "100%",
  },
  "inner-box": {
    p: { base: "0px 0px", lg: "60px 0px" },
    flexDirection: { base: "column", lg: "row" },
    alignItems: "center",
    justifyContent: { base: "center", lg: "space-between" },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "497px", xl: "630px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  "heading-one": {
    mt: { base: "24px", md: "24px", lg: "0px" },
    fontWeight: "700",
    fontSize: { base: "34px", lg: "45px", xl: "55px" },
    lineHeight: { base: "normal", md: "32px", lg: "48px", xl: "60px" },
    textAlign: "left",
    "> span": {
      fontFamily: `"Lora", serif`,
      fontWeight: "500",
    },
    maxWidth: "619px",
  },
  "heading-two": {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  text: {
    mt: { base: "24px", md: "24px", lg: "24px" },
    fontSize: { base: "16px", xl: "18px" },
    lineHeight: "150%",
    letterSpacing: "0",
    textAlign: "left",
    maxWidth: "619px",
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },
  "button-box": {
    mt: "32px",
    gap: "24px",
    alignSelf: { base: "center", lg: "flex-start" },
    flexDirection: { base: "column", lg: "row" },
    width: { base: "100%", md: "60%", lg: "auto" },
  },
  images: {
    position: "relative",
    maxWidth: "800px",
    mt: { base: "36px", lg: "0" },
  },
  "client-div": {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontSize: "16px",
    fontWeight: "700",
    m: { base: "0", lg: "0 14px", xl: "0 0 0 14px" },
    borderBottomLeftRadius: { base: "0", lg: "12px" },
    color: { base: "#333333", lg: "#FFFFFF" },
    overflow: "hidden",
    position: "relative",
  },
  clients: {
    flexDirection: { base: "column", lg: "row" },
    alignItems: { base: "flex-start", lg: "center" },
  },
  "client-heading": {
    width: "224px",
    flexShrink: "0",
    height: { base: "auto", lg: "122px" },
    position: { base: "static", lg: "absolute" },
    bg: { lg: "#033F47" },
    zIndex: 2,
    pb: { base: "16px", md: "0" },
  },
}

const navigateToSchedule = () =>
  navigate("https://avantgardehealth.com/schedule-a-demo/")
const SectionHero = () => {
  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.container}>
          <Box className="hero__content">
            <Flex sx={styles["inner-box"]}>
              <Flex sx={styles["left-box"]}>
                <Heading sx={styles["heading-one"]} as="h1">
                  Uncover Hidden Costs.{" "}
                  <span>Improve Efficiency. Drive Accountability.</span>
                </Heading>
                <Text sx={styles.text}>
                  Avant-Garde Health delivers advanced healthcare analytics
                  software that offers unparalleled detail in breaking down your
                  department and procedure data — including per unit supply
                  costs, personnel expenses, reimbursement by payer and more -
                  schedule a demo today!
                </Text>
                <Flex sx={styles["button-box"]}>
                  <button
                    className="btn btn--primary btn-hero-section btn--banner"
                    onClick={navigateToSchedule}
                  >
                    Schedule a Demo
                    <RightArrow />
                  </button>
                  <VideoModal />
                </Flex>
              </Flex>
              <Box sx={styles.images}>
                <SlideUp>
                  <StaticImage
                    src="../../images/banner/banner-image.png"
                    alt="Home page hero image"
                    placeholder="blurred"
                  />
                </SlideUp>
                <SlideUp
                  className={"banner-section__image-card-one"}
                  delay={0.25}
                >
                  <StaticImage
                    src="../../images/banner/card-one.png"
                    alt="Home page hero image"
                    placeholder="blurred"
                  />
                </SlideUp>
                <SlideUp
                  className={"banner-section__image-card-two"}
                  delay={0.45}
                >
                  <StaticImage
                    src="../../images/banner/cardTwo.png"
                    alt="Home page hero image"
                    placeholder="blurred"
                  />
                </SlideUp>
                <SlideUp
                  className={"banner-section__image-card-three"}
                  delay={0.65}
                >
                  <StaticImage
                    src="../../images/banner/card-three.png"
                    alt="Home page hero image"
                    placeholder="blurred"
                  />
                </SlideUp>
                <Box className={"banner-section__image-shadow"}>
                  <SlideUp>
                    <Shadow />
                  </SlideUp>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box sx={styles["client-div"]}>
        <Box sx={styles.clients}>
          <Box sx={styles.container}>
            <Text sx={styles["client-heading"]}>
              <span className="client-bg">&nbsp;</span>
              <span className="clients-header-text">
                Trusted By the Nation's Top Hospitals and ASCs
              </span>
              <span className="client-skew">
                <span className="client-skew-border"></span>
                <span className="client-skew-border"></span>
              </span>
            </Text>
          </Box>
          <Clients />
        </Box>
      </Box>
    </>
  )
}

export default SectionHero
