import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Box, Text, Heading } from "@chakra-ui/react"
import Slider from "react-slick"

import RightArrow from "../icons/right-arrow"
import LeftArrow from "../icons/left-arrow"

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    my: { base: "100px", lg: "200px" },
    pb: { base: "100px", lg: "0px" },
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: "0px 24px",
  },
  heading: {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: {base: "24px", lg:"28px", xl:"34px"},
    lineHeight: "1.4",
    color: "#111",
    mb: { base: "50px", md: "100px" },
  },
  text: {
    fontSize: {base: "16px", md:"18px", lg:"20px"},
    lineHeight: "1.4",
    color: "#FFFFFF",
    mt: { base: "8px", xl:"24px"},
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    flexShrink: "0",
    width: { base:"45px", md:"80px"},
    height: { base:"45px", md:"80px"},
    borderRadius: "50%",
    overflow: "hidden",
  },
  card: {
    px: "10px",
  },
  "card-content": {
    bg: "#1D273F",
    p: { base: "24px", lg:"40px"},
    flexDirection: "column",
    gap: "16px",
    borderRadius: "12px",
    justifyContent: "flex-start",
    height: { base: "500px", md: "516px", lg: "600px" },
  },
  name: {
    color: "#E3F97B",
    fontWeight: "500",
    fontSize: { base:"20px", md:"22px"},
  },
  post: {
    color: "#FFFFFF",
    fontWeight: "300",
    fontSize: "16px",
    mt: "8px",
  },
  profile: {
    gap: "16px",
    mt: "auto",
    pt: { base: "20px", md: "0"}
  }
}

const SectionQuote = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#e3f97b",
          color: "#033F47",
        }}
        onClick={onClick}
      >
        <RightArrow />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#e3f97b",
          color: "#033F47",
        }}
        onClick={onClick}
      >
        <LeftArrow />
      </div>
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    slidesToShow: 3,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 1284,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  }

  return (
    <Box sx={styles.root} className="testimonials">
      <Box sx={styles.container}>
        <Heading sx={styles.heading}>
          What Our Clients Are Saying
        </Heading>
        <Slider {...settings}>
          <Box sx={styles.card}>
            <Flex sx={styles["card-content"]}>
              <Box>
                <StaticImage
                  src="../../images/quote.png"
                  alt="Physician"
                  placeholder="blurred"
                />
              </Box>
              <Text sx={styles.text}>
              Avant-garde helps me answer questions that previously I thought were unanswerable. As a busy clinician, it is hard to take the time to dig up the granular detail that is essential to changing the way that we practice medicine. That is what Avant-garde Health enables us to do.
              </Text>
              <Flex sx={styles.profile}>
                <Box sx={styles.image}>
                  <StaticImage
                    src="../../images/clients/Harold-Fogel.jpg"
                    alt="Physician"
                    placeholder="blurred"
                  />
                </Box>  
                <Box>
                  <Text sx={styles.name}>Harold Fogel</Text>
                  <Text sx={styles.post}>Orthopaedic Spine Surgeon Massachusetts General Hospital</Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box sx={styles.card}>
            <Flex sx={styles["card-content"]}>
              <Box>
                <StaticImage
                  src="../../images/quote.png"
                  alt="Physician"
                  placeholder="blurred"
                />
              </Box>
              <Text sx={styles.text}>
              When a surgeon receives a report card from the Avant-garde platform, they can see where they rank relative to the top performers.  And then ask why, what am I doing differently?  It’s this curiosity and physician enablement that drive change and ultimately lead to reduced spending and improved patient outcomes.
              </Text>
              <Flex sx={styles.profile}>
                <Box sx={styles.image}>
                  <StaticImage
                    src="../../images/clients/Jason-E-Simon.png"
                    alt="Physician"
                    placeholder="blurred"
                  />
                </Box>
                <Box>
                  <Text sx={styles.name}>Jason Simon</Text>
                  <Text sx={styles.post}>Sport Medicine and Shoulder Reconstruction Surgeon Newton Wellesley Hospital</Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box sx={styles.card}>
            <Flex sx={styles["card-content"]}>
              <Box>
                <StaticImage
                  src="../../images/quote.png"
                  alt="Physician"
                  placeholder="blurred"
                />
              </Box>
              <Text sx={styles.text}>
                We are very happy with the gains we’ve achieved with the Avant-garde CareMeasurement Coding software and assistance from their Customer Success team.  Even though our coding processes and CDI reviews are very sophisticated, we’re identifying additional opportunities for improving our coding and billing precision with Avant-garde.
              </Text>
              <Flex sx={styles.profile}>
                <Box sx={styles.image}>
                  <StaticImage
                    src="../../images/clients/Marilyn-Voss.png"
                    alt="Physician"
                    placeholder="blurred"
                  />
                </Box>
                <Box>
                  <Text sx={styles.name}>Marilyn Voss</Text>
                  <Text sx={styles.post}>Director of Clinical Coding, CDI and Utilization Management Nebraska Methodist Health System</Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Slider>
      </Box>
    </Box>
  )
}

export default SectionQuote
