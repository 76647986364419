import React from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"

import SlideUp from "../slide-up/slide-up"
import RightArrow from "../icons/right-arrow"

const styles = {
  root: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: "300",
    bg: "#EDEDED",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    mx: "14px",
    borderRadius: "12px",
    pt: { base: "64px", lg: "109px" },
    textAlign: "left",
  },
  heading: {
    fontFamily: `"Lora", serif`,
    fontWeight: "500",
    fontSize: {base: "24px", lg:"28px", xl:"34px"},
    lineHeight: "1,4",
    color: "#111",
    mb: "24px",
  },
  container: {
    maxWidth: { base: "1520px" },
    mx: "auto",
    p: "0px 24px",
  },
  "button-link": {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    bg: "#E3F97B",
    color: "#134440",
    p: "12px 24px",
    borderRadius: "4px",
    maxWidth: "227px",
    mx: "auto",
  },
  text:{
    mb: "32px",
    fontSize: {base: "16px", lg:"18px"},
    color: "#333",
  },
  "heading-box": {
    flexDirection: { base: "column", lg: "row", xl: "row" },
    gap: { base: "50px", lg: "140px"}
  },
  images: {
    position: "relative",
    width: { base: "92%", lg:"150%", xl:"60%" },
    alignSelf: "center"
  },
}

const navigateToCaremeasurement = () => navigate("/caremeasurement/")

const SectionHowItWorks = () => (
  <Box sx={styles.root}>
    <Box sx={styles.container}>
      <Flex sx={styles["heading-box"]}>
        <Box sx={styles["content-box"]}>
          <Heading sx={styles.heading} className="section__title-transform-data">
            Transform Data into Actionable Insights
          </Heading>
          <Text sx={styles.text}>
            Turn complex data into simple, actionable steps that lead to
            better outcomes and increased savings.
          </Text>
          <Link className="btn btn--primary" href="https://avantgardehealth.com/schedule-a-demo/">
            Schedule a Demo
            <RightArrow />
          </Link>
        </Box>
        <Box sx={styles.images}>
          <SlideUp>
            <StaticImage
              src="../../images/transform-data/insight_biologics.svg"
              alt="Avant-garde Health logo"
              placeholder="blurred"
            />
          </SlideUp>
          <SlideUp className={"transform-data__image-card-one"} delay={0.25}>
            <StaticImage
              src="../../images/transform-data/transform-data-card-1.png"
              alt="Avant-garde Health logo"
              placeholder="blurred"
            />
          </SlideUp>
          <SlideUp className={"transform-data__image-card-two"} delay={0.45}>
            <StaticImage
              src="../../images/transform-data/transform-data-card-2.png"
              alt="Avant-garde Health logo"
              placeholder="blurred"
            />
          </SlideUp>
          <SlideUp className={"transform-data__image-card-three"} delay={0.65}>
            <StaticImage
              src="../../images/transform-data/transform-data-card-3.png"
              alt="Avant-garde Health logo"
              placeholder="blurred"
            />
          </SlideUp>
        </Box>
      </Flex>
    </Box>
  </Box>
)

export default SectionHowItWorks
