import React, { useEffect, useState, useRef } from "react"
import { motion } from "framer-motion"

const SlideUp = ({ children, delay = 0, className }) => {
  const [hasAnimated, setHasAnimated] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasAnimated(true)
          observer.unobserve(entry.target)
        }
      },
      { threshold: 0.8 }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  return (
    <motion.div
      ref={ref}
      initial={{ y: 30, opacity: 0 }}
      animate={hasAnimated ? { y: 0, opacity: 1 } : {}}
      transition={{
        duration: 0.7,
        ease: "easeOut",
        delay,
      }}
      className={className}
    >
      {children}
    </motion.div>
  )
}

export default SlideUp
